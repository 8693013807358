
/* Font loader ------------------*/

@font-face {
  font-family: 'GT America';
  src: url('fonts/GT-America-Standard-Regular.eot');
  src: url('fonts/GT-America-Standard-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/GT-America-Standard-Regular.woff') format('woff'),
  url('fonts/GT-America-Standard-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('fonts/GT-America-Standard-Bold.eot');
  src: url('fonts/GT-America-Standard-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/GT-America-Standard-Bold.woff') format('woff'),
  url('fonts/GT-America-Standard-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Global ------------------*/

html {
  font-size: 1vw;
}

body {
  background: #4242d1;
  height: 100vh;
  font-size: 5vh;
  font-family: 'GT America', Helvetica, sans-serif;
}

body.gallery-active,
body.menu-active {
  overflow: hidden;
}

::-moz-selection { background: #4242d1; color: #fff; text-shadow: none; }
::selection { background: #4242d1; color: #fff; text-shadow: none; }

a {
  color: currentColor;
  text-decoration: none;
}

.button {
  display: inline-block;
  line-height: 7rem;
  border-radius: 3.5rem;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  font-weight: 700;
  padding: 0 3.5rem;
  border: 1px solid currentColor;
  cursor: pointer;
}

.grid {
  position: absolute;
  width: 200%;
  left: -100%;
  height: 100vh;
  background: url('../assets/img/grid.svg') repeat center;
  user-select: none;
}

.logo {
  display: block;
  width: 100%;
  height: 100vh;
  background: url('../assets/img/logo.svg') no-repeat center;
}

h1 {
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 4rem;
}

.page-content {
  position: relative;
  z-index: 10;
  top: 0;
  left: 50%;
  width: 50%;
  padding-left: 20px;
  /*overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;*/
  background: #4242d1;
  transition: transform 0.5s ease;
  z-index: 5;
  overflow-x: hidden;
}

.page-content-holder {
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease;
}

/* Desktop only (as per framework.css) */
@media screen and (min-width: 801px) {
  
  .gallery-active .page-content-holder,
  .menu-active .page-content-holder {
    opacity: 0;
  }
  
}


.content-overlay {
  pointer-events: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #4242d1;
  opacity: 0;
  transition: opacity 0.5s ease;
}

@media screen and (min-width: 801px) {
  .gallery-active .page-content {
    transform: translate3d(100%, 0, 0);
  }
}

.text-block {
  background: white;
  color: #4242d1;
  padding: 5rem 2.7rem;
}

.text-block,
.text-block p {
  font-size: 1.8rem;
  line-height: 1.3em;
  margin-bottom: 0.625em;
}

.text-block strong {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
}

.text-block.intro {
  padding-bottom: 4rem;
}

.text-block.contact .hours {
  display: flex;
  flex-wrap: wrap;
}

.text-block.contact .hours .block {
  flex-basis: 50%;
  padding-bottom: 4rem;
}

hr {
  height: 1px;
  border: 0;
  background: #4242d1;
  margin: 2.7rem 0;
}

.animation-block {
  background: #4242d1;
  height: 100vh;
  background-size: 350px auto;
  background-position: center;
}

/* Nav ------------------*/

nav {
  position: fixed;
  z-index: 105;
  width: 40px;
  height: 100%;
  border-left: 1px solid white;
  border-right: 1px solid white;
  left: 50%;
  top: 0;
  transform: translate3d(-50%,0,0);
  color: white;
  background: #4242d1;
  transition: transform 0.5s ease;
  font-size: 16px;
  line-height: 1em;
  font-weight: 600;
}


@media screen and (min-width: 801px) {
  .close-mobile {
    display: none;
  }
  .gallery-active nav {
    transform: translate3d(calc(50vw - 40px),0,0);
  }
}

nav ul {
  position: relative;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  width: 500px;
  transform: translate3d(-50%, -50%, 0) rotate(-90deg);
  transition: opacity 0.5s ease;
  opacity: 1;
}

@media screen and (min-width: 801px) {
  .gallery-active nav ul,
  .menu-active nav ul {
    opacity: 0;
    pointer-events: none;
  }
}

nav ul li {
  display: inline-block;
}

nav .close {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  width: 100vh;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transform: translate3d(-50%, -50%, 0) rotate(-90deg);
  transition: opacity 0.5s ease;
  z-index: 20;
}


@media screen and (min-width: 801px) {
  .gallery-active nav .close,
  .menu-active nav .close {
    opacity: 1;
    pointer-events: auto;
  }
}

.close:hover {
  background: rgba(0,0,0,0.1);
}

/* Contact ------------------*/

.map-link {
  font-size: 16px;
  border-bottom: 1px dashed currentColor;
}

.map-link:hover {
  border-bottom-style: solid;
}

.social {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.social-item {
  margin: 0 auto;
}


.social-item:first-child {
  margin-left: 0;
}

.social-item:last-child {
  margin-right: 0;
}

.social a {
  width: 7rem;
  height: 7rem;
  border: 1px dashed currentColor;
  display: flex;
  border-radius: 100%;
}

.social a:hover {
  border-style: solid;
}

.social img {
  width: 25px;
  height: 25px;
  margin: auto;
}

.form {
  display: flex;
}

input[type="email"],
input[type="text"],
input[type="submit"] {
  appearance: none;
  -webkit-appearance: none;
  color: #4242d1;
  font-size: 16px;
  height: 7rem;
  line-height: 7rem;
  padding: 0 2rem;
  border-radius: 3.5rem;
  border: 1px dashed currentColor;
  background: white;
  margin: 0;
}

#mc_embed_signup input[type="email"] {
  box-sizing: border-box;
  width: 16rem;
}

input[type="email"],
input[type="text"] {
  border-right: 1px solid currentColor;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 3rem;
}

input[type="email"]:focus,
input[type="text"]:focus {
  border-style: solid;
}

input[type="submit"] {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-align: center;
  padding-right: 3rem;
  cursor: pointer;
}

input[type="submit"]:hover {
  border-style: solid;
}

/* Booking ------------------*/

.booking-widget {
  padding: 3rem 0 4rem;
}

.booking-widget .button {
  width: 100%;
}

/* Gallery ------------------*/

.gallery-trigger {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  z-index: 5;
  cursor: pointer;
}

.gallery-extra-ui {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  z-index: 6;
  width: calc(50% - 20px);
  transition: width 0.5s ease;
  pointer-events: none;
}

.gallery-button-mobile {
  display: none;
}

@media screen and (min-width: 801px) {
  .gallery-active .gallery-extra-ui {
    width: 100%;
    width: calc(100% - 40px);
  }
}

.gallery-next,
.gallery-prev {
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  width: 50%;
  height: 100vh;
  top: 0;
  /*background: url(../img/arrow.svg) no-repeat right 20px center;*/
  transition-property: opacity, transform;
  display: flex;
  user-select: none;
}

.gallery-arrow img {
  margin: auto;
  width: 32px;
  height: auto;
  margin-right: 20px;
  transition: transform 0.2s ease;
  transform: scale(1.0);
}

.gallery-arrow img:hover {
  transform: scale(1.2);
}

.gallery-arrow.hidden {
  opacity: 0;
  pointer-events: none;
}

.gallery-next {
  right: 0;
}

.gallery-prev {
  left: 0;
  transform: rotate(180deg);
}


@media screen and (min-width: 801px) {
  .gallery-active .gallery-trigger {
    display: none;
  }
}

.gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
}

.gallery.flickity-enabled {
  position: fixed;
  z-index: 0;
}

.gallery .carousel-cell {
  height: 100%;
  margin-right: 10px;
}

.gallery .carousel-cell img {
  height: 100%;
  width: auto;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.gallery .carousel-cell img.flickity-lazyloaded {
  opacity: 1;
}

.gallery .carousel-caption {
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-size: 15px;
  color: white;
  opacity: 0;
  transition: opacity 0.5s ease;
}


@media screen and (min-width: 801px) {
  .gallery-active .gallery .carousel-caption,
  .gallery .is-selected .carousel-caption {
    opacity: 1;
  }
}

.gallery-fullscreen {
  position: absolute;
  bottom: 13px;
  right: 20px;
  width: 19px;
  height: 19px;
  background: url(../assets/img/fullscreen.svg) no-repeat center;
  z-index: 10;
  cursor: pointer;
  pointer-events: auto;
  transition: 0.2s ease;
  transition-property: transform;
}

.gallery-fullscreen:hover {
  transform: scale(1.2);
}


@media screen and (min-width: 801px) {
  .gallery-active .gallery-fullscreen {
    background-image: url(../assets/img/fullscreen-close.svg);
  }
  
  .gallery-active .gallery-fullscreen:hover {
    transform: scale(0.8);
  }
}

.gallery .flickity-page-dots {
  box-sizing: border-box;
  bottom: 12px;
  width: 50%;
  width: calc(50% - 80px);
  transition: width 0.5s ease;
  text-align: right;
}


@media screen and (min-width: 801px) {
  .gallery-active .gallery .flickity-page-dots {
    width: 100%;
    width: calc(100% - 100px);
  }
}

.gallery .flickity-page-dots .dot {
  width: 7px;
  height: 7px;
  margin: 2px;
  border: 1px solid white;
  background: rgba(0,0,0,0.5);
  opacity: 1;
}

.gallery .flickity-page-dots .dot.is-selected {
  opacity: 1;
  background: white;
}

/* Menus ------------------*/

.menu-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4rem 0 2rem;
}

.menu-btns .menu-btn {
  position: relative;
  width: 48%;
  padding-top: 48%;
  border-radius: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
}

.menu-btns .menu-btn label {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  z-index: 10;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  pointer-events: none;
  text-align: center;
}

.menu-btns .menu-btn img,
.menu-btns .menu-btn:after {
  position: absolute;
  width: 85%;
  height: 85%;
  top: 7.5%;
  left: 7.5%;
  border-radius: 100%;
}

.menu-btns .menu-btn img {
  object-fit: cover;
}

.menu-btns .menu-btn svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.menu-btns .menu-btn svg g {
  transition: all 0.5s ease;
  stroke-dasharray: 4 5;
}

.menu-btns .menu-btn:hover svg g {
  stroke-dasharray: 20 0;
}

.menu-btns .menu-btn:after {
  content: "";
  background: #A4341E;
  opacity: 0.4;
  mix-blend-mode: multiply;
  transition: 0.5s ease;
  transition-property: opacity, background;
}

.menu-btns .menu-btn:hover:after {
  background: #4242d1;
  opacity: 0.6;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 100;
}

.menu-animation,
.menu-panel {
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  background: #4242d1;
  transform: translate3d(-100%,0,0);
  will-change: transform;
  transition: transform 0.66s ease;
  pointer-events: auto;
  z-index: 10;
}

.menu-panel {
  transform: translate3d(100%,0,0);
  background: white;
  left: 50%;
  color: #4242d1;
  font-size: 1.5rem;
  padding-left: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.menu-content {
  display: flex;
  flex-direction: column;
  /*flex-wrap: wrap;*/
  align-items: flex-start;
  align-content: flex-start;
}

.menu-content.reservations {
  height: 100% !important;
}

.menu-content.reservations h1 {
  padding: 5rem 2.7rem 2.5rem;
  margin: 0;
}

.menu.open .menu-animation,
.menu.open .menu-panel {
  transform: translate3d(0,0,0);
}

.menu-animation img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-item {
  margin-left: 2.75rem;
  width: 20rem;
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #4242d1;
}

.menu-item h2 {
  font-size: 1.5rem;
  margin-top: 0.75em;
  font-weight: 700;
}

.item-tags {
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
}

.item-tags span {
  display: inline-block;
  margin-right: 1em;
}

.input-group {
  visibility: hidden;
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: currentColor;
}
::-moz-placeholder { /* Firefox 19+ */
  color: currentColor;
}
:-ms-input-placeholder { /* IE 10+ */
  color: currentColor;
}
:-moz-placeholder { /* Firefox 18- */
  color: currentColor;
}


/* Typography ------------------*/


.footer {
  border-top: 1px solid #4242d1;
  color: #4242d1;
  background: white;
  font-size: 13px;
  padding: 15px;
  display: flex;
  line-height: 1.8em;
}

.footer p {
  margin: 0;
}

.footer a {
  color: inherit;
}

.footer-col {
  width: 50%;
}

.cookies-bar {
  position: fixed;
  z-index: 99999999;
  bottom: 0;
  background: #4242d1;
  width: 100vw;
  display: none;
}

.cookies-text {
  color: #fff;
  padding: 30px 30px 50px;
  font-size: 16px;
  line-height: 1.5em;
  max-width: 60%;
  margin: 0 auto;
  overflow: hidden;
}

.cookies-text a {
  border-bottom: 1px solid;
}

.cookies-button {
  position: absolute;
  top: 20px;
  right: 30px;
  background: none;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: normal;
  border: none;
  line-height: 1em;
  color: #fff;
  padding: 0;
}


/* Media Queries ------------------*/

@media screen and (max-width: 1000px) {
  .social-item:first-child {
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 40px;
  }
  .social-item:nth-child(2) {
    margin-left: auto;
    margin-left: 10px;
    margin-bottom: 40px;
  }
  
  .social-item:last-child {
    margin: 0;
  }
  
  #mc_embed_signup input[type="email"] {
    width: 80%;
  }
}

/* Medium (as per framework.css) */

@media screen and (max-width: 800px) {
  
  html {
    font-size: 3.5vw;
  }
  
  body {
    overflow: inherit;
  }
  
  .page-content {
    position: relative;
    left: 0;
    bottom: auto;
    padding: 0;
    width: 100%;
  }
  
  .text-block {
    padding: 5rem 1.5rem;
  }
  
  .intro-link {
    display: block;
    margin-bottom: 100vh;
  }
  
  nav,
  .gallery-arrow {
    display: none;
  }
  
  .gallery,
  .gallery-trigger,
  .gallery-extra-ui {
    z-index: 10;
    position: absolute;
    top: 76vw;
    left: 0;
    height: 100vh;
  }
  
  .gallery-trigger,
  .gallery-extra-ui {
    z-index: 15;
    width: 100%;
  }
  
  .gallery .flickity-page-dots {
    /*width: 100%;
    width: calc(100% - 40px);*/
    display: none;
  }
  
  .gallery-fullscreen {
    display: none;
    /*right: 10px;
    bottom: 10px;*/
  }
  
  .gallery-button-mobile {
    color: white;
    display: block;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    height: 7rem;
    line-height: 7rem;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;
    font-weight: 700;
    border-radius: 3.5rem;
    border: 1px dashed white;
  }
  
  .close-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    line-height: 40px;
    background: #4242d1;
    z-index: 15;
    text-align: center;
    font-size: 16px;
    color: white;
    font-weight: 700;
    transition: transform 0.5s ease;
    transform: translate3d(0,-100%,0);
  }
  
  .gallery .carousel-cell img {
    width: 100vw;
    object-fit: cover;
    object-position: center;
  }
  
  .gallery.flickity-enabled {
    position: absolute;
    z-index: 10;
  }
  
  .gallery-active .gallery {
    position: fixed;
    top: 0;
    overflow: hidden;
    height: 100vh;
  }
  
  .gallery-active .close-mobile {
    transform: translate3d(0,0,0);
  }
  
  .gallery-active .gallery-trigger,
  .gallery-active .flickity-page-dots,
  .gallery-active .gallery-extra-ui {
    display: none;
  }
  
  .gallery-active .flickity-viewport {
    height: 100vh;
    touch-action: none !important;
  }
  
  .gallery-active .flickity-slider {
    left: 0px !important;
    top: 40px;
    transform: none !important;
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: calc(100vh - 40px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .gallery-active .gallery .carousel-cell {
    position: relative !important;
    left: 0 !important;
    height: auto;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .gallery-active .gallery .carousel-cell img {
    width: 100%;
    height: auto;
  }
  
  .grid {
    bottom: auto;
    height: 76vw;
    animation: 50s logo infinite linear;
    background-repeat: repeat;
    background-image: url('../assets/img/grid-mobile.svg');
  }
  .logo {
    height: 76vw;
    background-image: url('../assets/img/logo-mobile.svg');
  }
  .grid, .logo {
    background-position: center;
  }
  
  .text-block.contact .hours {
    flex-direction: column;
  }
  
  .menu-btns {
    flex-direction: column;
  }
  
  .menu-btns .menu-btn {
    width: 100%;
    padding-top: 100%;
  }
  
  .menu-btns .menu-btn:after,
  .menu-btns .menu-btn img {
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
  }
  
  .menu-animation {
    display: none;
  }
  
  .menu-panel {
    left: 0;
    width: 100%;
    padding: 0;
  }
  
  .close-mobile.menu-close-mobile {
    position: fixed;
    top: 0;
    transform: translate3d(0,0,0);
  }
  
  .menu-panel .menu-content {
    height: auto !important;
  }
  
  .menu-panel .menu-content.reservations {
    height: 100% !important;
  }
  
  .menu-panel .menu-content.reservations h1 {
    text-align: center;
    width: 100%;
  }
  
  .menu-content {
    padding: 1rem 1.5rem 3rem;
  }
  
  .menu-item {
    width: 100%;
    margin-left: 0;
  }
  
  .menu-item:last-child {
    border: 0;
  }
  
  .footer {
    flex-direction: column-reverse;
  }
  
  .footer-col {
    width: 100%;
  }
  
  .footer-col:nth-child(2) {
    margin-bottom: 1em;
  }

  .cookies-text {
    max-width: 85%;
    padding: 30px;
  }
}

@keyframes logo {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100px 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* Small (as per framework.css) */

@media screen and (max-width: 600px) {
  
}


#CybotCookiebotDialog h2 {
	font-size: 20px !important;
	line-height: 33px !important;
	font-family: $secondary-font !important;
	color: black !important;
	font-weight: 700 !important;
	margin-bottom: 30px !important;
	letter-spacing: 0px !important;
}

#CybotCookiebotDialog *, #CybotCookiebotDialogBodyUnderlay * {
	font-family: $secondary-font, serif !important;
	letter-spacing: 0px !important;
	font-size: 16px !important;
	line-height: 22px !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection, #CybotCookiebotDialogBodyButtonDecline, #CybotCookiebotDialogBodyLevelButtonCustomize, #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
	background-color: #fff !important;
	border-color: #4242d1 !important;
	color: #000000 !important;
	font-family: $secondary-font, sans-serif !important;
	font-weight: 700 !important;
	text-transform: uppercase !important;
	letter-spacing: 0px !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
	background-color: #4242d1 !important;
	border-color: #4242d1 !important;
	color: #ffffff !important;
}

#CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonDescription {
	font-family: $secondary-font, sans-serif !important;
	font-weight: 700 !important;
	text-transform: uppercase !important;
	letter-spacing: 0px !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink {
	color: #000000 !important;
}
